import * as React from "react"
import { useEffect, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { PIPELINE } from "../taskHelper"

const TagListView = ({ tags, taskId, transactionTypeId, listViewType }) => {
  const [assignBlock, setAssignBlock] = useState("")
  const [tagColor, setTagColor] = useState("")
  const [assignTag, setAssignTag] = useState([])
  const [assignTags, setAssignTags] = useState([])
  const [assignTagsScore, setAssignTagsScore] = useState(null)

  const handleAssignBlock = tagName => {
    setAssignBlock(tagName)
  }

  const handleTagColor = (tagType, tag, tags) => {
    setAssignTags(tags)
    setAssignTag([tag])
    let type = tagType
    switch (type) {
      case "blue":
        setTagColor("blue")
        break
      case "pink":
        setTagColor("pink")
        break
      case "beige":
        setTagColor("beige")
        break
      case "green":
        setTagColor("green")
        break
      case "gray":
        setTagColor("gray")
        break
      default:
        setTagColor("blue")
    }
  }

  useEffect(() => {
    if (assignTag.length > 0) {
      assignTag[0].color = tagColor
    }
    assignTag.forEach(elements => {
      const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
      if (tagNames > -1) {
        assignTags[tagNames] = elements
      } else {
        assignTags = assignTags.push(elements)
      }
    })
    if (assignTag.length > 0) {
      AxiosInstance.post(`task/update-tags/${taskId}`, {
        tags: assignTags,
      })
        .then(response => {})
        .catch(error => {
          console.log(error)
        })
    }
  }, [tagColor])

  const changeTagScore = (e, tag, tags) => {
    setAssignTagsScore(e.target.value)
    setAssignTags(tags)
    setAssignTag([tag])
  }

  const handleTagScore = assignTagsScore => {
    if (assignTagsScore !== null) {
      if (assignTag.length > 0) {
        assignTag[0].score = assignTagsScore
      }
      assignTag.forEach(elements => {
        const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
        if (tagNames > -1) {
          assignTags[tagNames] = elements
        } else {
          assignTags = assignTags.push(elements)
        }
      })
      if (assignTag.length > 0) {
        AxiosInstance.post(`task/update-tags/${taskId}`, {
          tags: assignTags,
        })
          .then(response => {
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }

  return (
    <div className="SwiftCloudTableTags">
      <ul>
        {tags.length > 0 &&
          tags.map((tagDetail, tagIndex) => {
            const filterLink = [
              transactionTypeId ? `transactionTypeId=${transactionTypeId}` : "",
              tagDetail.tag ? `tag=${encodeURIComponent(tagDetail.tag)}` : "",
              listViewType === PIPELINE ? `view=${PIPELINE}` : "",
            ]
              .filter(Boolean)
              .join("&")

            const fullLink = filterLink ? `?${filterLink}` : ""
            return (
              <li
                key={tagIndex}
                className={assignBlock == tagDetail ? `tag-${tagColor}` : `tag-${tagDetail.color}`}
                onMouseEnter={() => handleAssignBlock(tagDetail)}
                onMouseLeave={() => setAssignBlock("")}
              >
                <a key={tagIndex} href="#" data-tagname="Designer">
                  {tagDetail.tag}
                  {tagDetail.score == 0 ? "" : ` (${tagDetail.score})`}
                </a>
                {assignBlock == tagDetail && (
                  <div className="SwiftCloudTagScore">
                    <div className="mb-2 SwiftCloudTagScoreType">
                      <a href={`${fullLink}`}>
                        {" "}
                        <i className="bi bi-filter"></i>{" "}
                      </a>
                      <a
                        className="SwiftCloudTagScoreBlue"
                        onClick={() => {
                          handleTagColor("blue", tagDetail, tags)
                        }}
                        data-type="blue"
                      />
                      <a
                        className="SwiftCloudTagScorePink"
                        onClick={() => {
                          handleTagColor("pink", tagDetail, tags)
                        }}
                        data-type="pink"
                      />
                      <a
                        className="SwiftCloudTagScoreBeige"
                        onClick={() => handleTagColor("beige", tagDetail, tags)}
                        data-type="beige"
                      />
                      <a
                        className="SwiftCloudTagScoreGreen"
                        onClick={() => handleTagColor("green", tagDetail, tags)}
                        data-type="green"
                      />
                      <a
                        className="SwiftCloudTagScoreGray"
                        onClick={() => handleTagColor("gray", tagDetail, tags)}
                        data-type="gray"
                      />
                    </div>
                    <span>Assign Tag Score / Value</span>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control tagScoreInput"
                        defaultValue={tagDetail.score}
                        onChange={event => changeTagScore(event, tagDetail, tags)}
                        onKeyUp={event => {
                          if (event.key === "Enter") {
                            handleTagScore(assignTagsScore, tagDetail, tags)
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-success btnSaveTagScore"
                        onClick={() => handleTagScore(assignTagsScore, tagDetail, tags)}
                        onMouseLeave={() => handleTagScore(assignTagsScore, tagDetail, tags)}
                      >
                        <i className="bi bi-check2" />
                      </button>
                    </div>
                  </div>
                )}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default TagListView
